exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-result-index-tsx": () => import("./../../../src/pages/result/index.tsx" /* webpackChunkName: "component---src-pages-result-index-tsx" */),
  "component---src-pages-result-status-tsx": () => import("./../../../src/pages/result/status.tsx" /* webpackChunkName: "component---src-pages-result-status-tsx" */),
  "component---src-pages-search-issue-tsx": () => import("./../../../src/pages/search/issue.tsx" /* webpackChunkName: "component---src-pages-search-issue-tsx" */),
  "component---src-pages-search-organization-tsx": () => import("./../../../src/pages/search/organization.tsx" /* webpackChunkName: "component---src-pages-search-organization-tsx" */),
  "component---src-pages-search-project-tsx": () => import("./../../../src/pages/search/project.tsx" /* webpackChunkName: "component---src-pages-search-project-tsx" */),
  "component---src-pages-site-policy-tsx": () => import("./../../../src/pages/site-policy.tsx" /* webpackChunkName: "component---src-pages-site-policy-tsx" */),
  "component---src-templates-completion-report-tsx": () => import("./../../../src/templates/completionReport.tsx" /* webpackChunkName: "component---src-templates-completion-report-tsx" */),
  "component---src-templates-evaluation-plan-tsx": () => import("./../../../src/templates/evaluationPlan.tsx" /* webpackChunkName: "component---src-templates-evaluation-plan-tsx" */),
  "component---src-templates-exante-evaluation-report-tsx": () => import("./../../../src/templates/exanteEvaluationReport.tsx" /* webpackChunkName: "component---src-templates-exante-evaluation-report-tsx" */),
  "component---src-templates-expost-evaluation-report-tsx": () => import("./../../../src/templates/expostEvaluationReport.tsx" /* webpackChunkName: "component---src-templates-expost-evaluation-report-tsx" */),
  "component---src-templates-financial-plan-tsx": () => import("./../../../src/templates/financialPlan.tsx" /* webpackChunkName: "component---src-templates-financial-plan-tsx" */),
  "component---src-templates-financial-report-tsx": () => import("./../../../src/templates/financialReport.tsx" /* webpackChunkName: "component---src-templates-financial-report-tsx" */),
  "component---src-templates-interim-report-tsx": () => import("./../../../src/templates/interimReport.tsx" /* webpackChunkName: "component---src-templates-interim-report-tsx" */),
  "component---src-templates-main-tsx": () => import("./../../../src/templates/main.tsx" /* webpackChunkName: "component---src-templates-main-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/newsPage.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-organization-tsx": () => import("./../../../src/templates/organization.tsx" /* webpackChunkName: "component---src-templates-organization-tsx" */),
  "component---src-templates-progress-report-tsx": () => import("./../../../src/templates/progressReport.tsx" /* webpackChunkName: "component---src-templates-progress-report-tsx" */),
  "component---src-templates-project-plan-tsx": () => import("./../../../src/templates/projectPlan.tsx" /* webpackChunkName: "component---src-templates-project-plan-tsx" */),
  "component---src-templates-selected-project-tsx": () => import("./../../../src/templates/selectedProject.tsx" /* webpackChunkName: "component---src-templates-selected-project-tsx" */)
}

